import { useMutation } from "@tanstack/react-query";
import { toast } from "@twocontinents/dashboard/shared";
import { useAuth } from "@twocontinents/dashboard/shared/auth";
import {
  ApiError,
  ApplicationErrorCode,
  components,
  env,
} from "@twocontinents/shared";

import { useAgeGroupTicketsFiltersQueryParams } from "../hooks/use-age-group-tickets-filters-query-params";
import { useInvalidateGetAgeGroupTickets } from "./get-age-group-tickets";

type CreateAgeGroupTicketsRequest =
  components["schemas"]["CreateAgeGroupTicketsRequest"];

export interface Request {
  id: number;
  body: CreateAgeGroupTicketsRequest;
  ageGroupId: number;
  file: File | null;
}

// eslint-disable-next-line max-lines-per-function
const createTickets = async (
  { body, id, ageGroupId, file }: Request,
  token: string,
) => {
  const formData = new FormData();
  console.log(body);
  if (file) {
    formData.append("ticketsFile", file);
  }
  Object.entries(body).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item, index) => {
        if (typeof item === "object" && item !== null) {
          Object.entries(item).forEach(([itemKey, itemValue]) => {
            console.log(itemKey, itemValue);
            formData.append(`${key}[${index}].${itemKey}`, String(itemValue));
          });
        } else {
          formData.append(`${key}[${index}]`, String(item));
        }
      });
    } else {
      formData.append(key, String(value));
    }
  });

  return await fetch(
    `${env.API_URL}/api/v1/private/attractions/variants/${id}/age-groups/${ageGroupId}/tickets`,
    {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  ).then((res) => {
    if (!res.ok) {
      return res.json().then((err) => {
        throw err;
      });
    }
    return res;
  });
};

export const useCreateTickets = (variantId: number, ageGroupId: number) => {
  const { token } = useAuth();

  const { invalidate } = useInvalidateGetAgeGroupTickets();
  const { query } = useAgeGroupTicketsFiltersQueryParams();
  const { mutate, isPending } = useMutation({
    mutationFn: ({ body, file, id, ageGroupId }: Request) =>
      createTickets({ body, id, ageGroupId, file }, token ?? ""),
    onSuccess: async () => {
      toast.success("Ticket created successfully");
      await invalidate(variantId, ageGroupId, query);
    },
    onError: (err: ApiError) => {
      switch (err.applicationErrorCode) {
        case ApplicationErrorCode.TICKET_ALREADY_EXISTS: {
          toast.error("Ticket already exists");
          break;
        }
        case ApplicationErrorCode.REQUESTED_TICKETS_ARE_NOT_UNIQUE: {
          toast.error("Requested tickets are not unique");
          break;
        }
        default: {
          toast.error("Failed to create ticket");
          break;
        }
      }
    },
  });

  return {
    createTicket: mutate,
    isPending,
  };
};
