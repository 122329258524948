import { useMutation, useQueryClient } from "@tanstack/react-query";
import { routes, toast } from "@twocontinents/dashboard/shared";
import { useAuth } from "@twocontinents/dashboard/shared/auth";
import { components, env } from "@twocontinents/shared";
import { useNavigate } from "react-router-dom";

type CreateInvoiceRequest =
  components["schemas"]["CreateTravelAgencyInvoiceRequest"];

interface Props {
  agencyId: number;
  body: CreateInvoiceRequest;
  file: File;
}

const createInvoice = async (
  { body, agencyId, file }: Props,
  token: string,
) => {
  const formData = new FormData();
  formData.append("file", file);
  Object.entries(body).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((v, index) => {
        Object.entries(v).forEach(([k, v]) => {
          formData.append(`${key}[${index}].${k}`, v as string);
        });
      });
    } else {
      formData.append(key, value as string);
    }
  });

  return await fetch(
    `${env.API_URL}/api/v1/private/partners/travel-agencies/${agencyId}/invoices`,
    {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  ).then((res) => {
    if (!res.ok) {
      return res.json().then((err) => {
        throw err;
      });
    }
    return res;
  });
};

// eslint-disable-next-line max-lines-per-function
export const useCreateInvoice = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { token } = useAuth();

  const { mutate, isPending } = useMutation({
    mutationFn: (props: Props) => createInvoice(props, token ?? ""),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["private-invoices"] });
      navigate(routes.travelAgencies.invoices.index);
      toast.success("Invoice created successfully");
    },
    onError: () => {
      toast.error("Error creating invoice");
    },
  });

  return { createInvoice: mutate, isPending };
};
