import { useMutation } from "@tanstack/react-query";
import { toast, usePrivateApiClient } from "@twocontinents/dashboard/shared";
import { components } from "@twocontinents/shared";

import { useInvalidateGetTravelAgency } from "./get-travel-agency";

type UpdateTravelAgency = components["schemas"]["UpdateTravelAgencyRequest"];

export const useUpdateTravelAgency = (id: number) => {
  const apiClient = usePrivateApiClient();
  const { invalidate } = useInvalidateGetTravelAgency();

  const { mutate, isPending } = useMutation({
    mutationFn: (request: UpdateTravelAgency) => {
      return apiClient
        .PATCH("/private/partners/travel-agencies/{id}", {
          body: request,
          params: {
            path: {
              id,
            },
          },
        })
        .then((response) => response.data);
    },
    onSuccess: async () => {
      toast.success("Travel agency updated successfully");
      await invalidate(id);
    },
    onError: () => {
      toast.error("Failed to update travel agency");
    },
  });

  return { updateTravelAgency: mutate, isPending };
};
