import { useMutation } from "@tanstack/react-query";
import { toast } from "@twocontinents/dashboard/shared";
import { useAuth } from "@twocontinents/dashboard/shared/auth";
import { env } from "@twocontinents/shared";

import { useInvalidateGetOrder } from "./get-order";

interface Props {
  ticket: File;
  reservationId: number;
}

const assignTicketToReservation = async (
  { reservationId, ticket }: Props,
  token: string,
) => {
  const formData = new FormData();

  formData.append("ticket", ticket);

  return await fetch(
    `${env.API_URL}/api/v1/private/orders/attraction/reservations/${reservationId}/ticket`,
    {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  ).then((res) => {
    if (!res.ok) {
      return res.json().then((err) => {
        throw err;
      });
    }
    return res;
  });
};

export const useAssignTicketToReservation = (orderId: number) => {
  const { token } = useAuth();

  const invalidateGetOrder = useInvalidateGetOrder();

  const { mutate, isPending } = useMutation({
    mutationFn: (body: Props) => assignTicketToReservation(body, token ?? ""),
    onSuccess: async () => {
      toast.success("Ticket assigned successfully");
      await invalidateGetOrder(orderId);
    },
    onError: () => {
      toast.error("Failed to assign ticket");
    },
  });

  return {
    assignTicket: mutate,
    isPending,
  };
};
